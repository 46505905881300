import React from 'react';
import Geospatial from '@page-components/open-source/SubPages/Geospatial';
import OpenSourcePageWrapper from '@page-components/open-source/OpenSourcePageWrapper';

const OpenSourcePage = () => {
  return (
    <OpenSourcePageWrapper>
      <Geospatial />
    </OpenSourcePageWrapper>
  );
};

export default OpenSourcePage;
