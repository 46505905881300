import './Geospatial.scss';
import React from 'react';
import links from '@constants/links';
import Title from '../components/Title';
import Card from '../components/Card';
import Buttons from '../components/Card/components/Buttons';
import GithubIcon from '../../assets/github.svg';
import PDALIcon from './assets/pdal.svg';
import GDALIcon from './assets/gdal.svg';
import RasterioIcon from './assets/rasterio.svg';
import GeopandasIcon from './assets/geopandas.svg';
import MapserverIcon from './assets/mapserver.svg';
import StacIcon from './assets/stac.svg';
import QgisIcon from './assets/qgis.svg';
import CommunityImage from './assets/texture_community_geo_tools.png';
import InteroperabilityImage from './assets/texture_collaboration.png';

const Geospatial: React.FC = () => {
  return (
    <div className="TDB-OpenSourcePage__Geospatial">
      <Title>Geospatial</Title>
      <Card
        gradientColor="hsla(97, 91%, 45%, 0.7)"
        title="TileDB-Viz"
        description="Create beautiful visualizations from TileDB arrays using Babylon.js, natively within web applications via TypeScript."
      >
        <Buttons items={[{ text: 'View on Github', icon: GithubIcon, link: links.github.tiledbViz }]} />
      </Card>
      <Card
        gradientColor="rgba(255, 2, 15, 0.5)"
        title="TileDB-PyBabylonJS"
        description="Interactively visualize TileDB array data using Babylon.js within a Python Jupyter Notebook."
      >
        <Buttons items={[{ text: 'View on Github', icon: GithubIcon, link: links.github.tiledbPyBabylonJS }]} />
      </Card>

      <Card
        gradientColor="#0058F8"
        title="TileDB-CF-Py"
        description="Work with HDF-like data in TileDB. Support for the NetCDF data model using grouped TileDB arrays that satisfy the CF metadata conventions."
      >
        <Buttons items={[{ text: 'View on Github', icon: GithubIcon, link: links.github.tiledbCFPy }]} />
      </Card>

      <Card
        gradientColor="#0058F8"
        title="TileDB-Segy"
        description="Seismic data analysis that combines an intuitive segyio-like API with TileDB array storage."
      >
        <Buttons items={[{ text: 'View on Github', icon: GithubIcon, link: links.github.tiledbSegy }]} />
      </Card>

      <Card
        gradientColor="#0BDEFB"
        title="In the community"
        description="TileDB makes upstream contributions to several important open geospatial tools, including PDAL, GDAL, and Rasterio."
        backgroundImage={CommunityImage}
      >
        <div className="TDB-OpenSourcePage__Geospatial__in-the-community__icons">
          <img src={PDALIcon} alt="pdal" />
          <img src={GDALIcon} alt="gdal" />
          <img src={RasterioIcon} alt="rasterio" />
        </div>
      </Card>

      <Card
        gradientColor="#0BDEFB"
        title="Interoperability"
        description="TileDB's geospatial tools are designed to work with the systems you already know and love, like GeoPandas, MapServer, QGIS, and the STAC metadata specifications."
        backgroundImage={InteroperabilityImage}
      >
        <div className="TDB-OpenSourcePage__Geospatial__interoperability__icons">
          <img src={GeopandasIcon} alt="geopandas" />
          <img src={MapserverIcon} alt="mapserver" />
          <img src={QgisIcon} alt="qgis" />
          <img src={StacIcon} alt="stac" />
        </div>
      </Card>
    </div>
  );
};

export default Geospatial;
